import { converter, db9 } from "@/firebase"
import { useFirestore } from "@vueuse/firebase/useFirestore"
import { addDoc, collection, orderBy, query } from "firebase/firestore"
import { defineStore } from "pinia"
import { computed } from "vue"
import { useAuthStore } from "./auth";
import { Feedback } from "@/models/types";
import { useInterfaceStore } from "@/store/interface";


export const useFeedBackStore = defineStore("feedback", () => {
  const interfaceStore = useInterfaceStore();
  const user = useAuthStore().user
  const version = useAuthStore().appVersion
  //const userType = useAuthStore()

  const feedbackQuery = computed(() =>
    query(
      collection(db9, 'feedback'),
      orderBy("time", "desc")
    ).withConverter(converter<Feedback>())
  )

  const allFeedback = useFirestore<Feedback>(feedbackQuery);

  const saveFeedbackToFirebase = async (feedback : Feedback) => {
        const ref = collection(db9, `feedback`)
        await addDoc(ref, feedback)
        interfaceStore.NotifySuccess('Feedback submitted')
    }

    const createFeedback = (payload: { message: string }) => {
      if (!user){
        return
      }
      const { message } = payload
      const newFeedback: Feedback = {
        message, 
        time: new Date(),
        userType: user.roleType,
        clubId: user.clubId,
        clubName: user.club,
        version: version,
      }
      saveFeedbackToFirebase(newFeedback)
    }


    

  return {
    allFeedback,
    createFeedback,
  }
})