
import { db9 } from "@/firebase"
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { DateTime } from "luxon"
import { AdminUser } from "@/models/types"
import { ref } from "vue"


const shouldLogLoginToday = async (userId: string) => {
    const userRef = doc(db9, `users/${userId}`)
    const userDoc = await getDoc(userRef)
    const userData = ref<AdminUser>()

    if (userDoc.exists()) {
      if (!userDoc.data().lastTrainingLogin) {
        return true
      }
      const result: AdminUser = {
        id: userDoc.data().id,
        SLSNZNumber: userDoc.data().SLSNZNumber,
        club: userDoc.data().club,
        regions: userDoc.data().regions,
        clubId: userDoc.data().clubId,
        email: userDoc.data().email,
        firebaseUID: userDoc.data().firebaseUID,
        firstName: userDoc.data().firstName,
        lastName: userDoc.data().lastName,
        roleType: userDoc.data().roleType,
        type: userDoc.data().type,
        lastTrainingLogin: userDoc.data().lastTrainingLogin.toDate(),
      }

      userData.value = result
    }

    if (userData.value?.lastTrainingLogin) {
      const lastLogin = DateTime.fromJSDate(userData.value?.lastTrainingLogin);
      const today = DateTime.now();
      return !lastLogin.hasSame(today, 'day');
      
    } else {
      return true
    }
}

export const updateTrainingLoginTime = async (user: AdminUser) => {

    if (await shouldLogLoginToday(user.firebaseUID)) {
      const userRef = doc(db9, `users/${user?.firebaseUID}`)
      updateDoc(userRef, {lastTrainingLogin: new Date()})
    } else {
      return
    }
}