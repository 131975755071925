import { BreadCrumb } from "@/models/store/breadcrumb_types"
import { defineStore } from "pinia"

export interface BreadCrumbState {
  breadCrumb: BreadCrumb
}

export const useBreadCrumbStore = defineStore('breadCrumb', {
  state: (): BreadCrumbState => ({
    breadCrumb: {
      incident: null,
      patient: null,
      vital: null
    }
  }),
  actions: {
    clearBreadCrumb() {
      this.breadCrumb = {
        incident: null,
        patient: null,
        vital: null
      }
    },
    UPDATE_BREAD_CRUMB_INCIDENT(payload: string) {
      let breadCrumb = this.breadCrumb
      this.breadCrumb = {
        incident: {
          title: payload,
          disabled: true,
          href: `/incident/${payload}`
        },
        patient: null,
        vital: null
      }
    },
    UPDATE_BREAD_CRUMB_PATIENT(payload: { text: string; id: string }) {
      const breadCrumb = { ...this.breadCrumb }
      if (breadCrumb.incident !== null) {
        breadCrumb.incident.disabled = false
        breadCrumb.patient = {
          title: payload.text,
          disabled: true,
          href: `/patient/${payload.id}`
        }
        breadCrumb.vital = null
      }
      this.breadCrumb = breadCrumb
    }
  },
  getters: {
    getBreadCrumb(): BreadCrumb {
      return this.breadCrumb
    }
  }
})