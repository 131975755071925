import { AdminUser, LoginForm, MessageBus, RoleType } from '@/models/types'
import { converter, db9, fbAuth } from '@/firebase'
import { defineStore } from 'pinia'
import { FirestoreError, collection, doc, getDoc, getFirestore, query, where } from 'firebase/firestore'
import { useInterfaceStore } from './interface'
import { computed, ref } from 'vue'
import { useFirestore } from '@vueuse/firebase/useFirestore'
import { User, signInWithEmailAndPassword, signOut as signUserOut } from 'firebase/auth'

import * as Sentry from "@sentry/vue";

export const useAuthStore = defineStore("auth", () => {
  const firebaseUserToken = ref<string>(localStorage.getItem('patrol_firebase_token') ?? "" as string);
  // @ts-ignore
  const appVersion = ref<string>(PACKAGE_VERSION || '0');


  const loginUser = async (loginForm: LoginForm): Promise<MessageBus> => {
    if (!loginForm) {
      return {
        message: 'Login details incorrect',
        success: false,
      }
    }
    try {
    const data = await signInWithEmailAndPassword(fbAuth, loginForm.email, loginForm.password);
        // Bind User
        if (data.user) {
          const userToken = await data.user.getIdToken();
          firebaseUserToken.value = userToken;
          localStorage.setItem('patrol_firebase_token', userToken)
          setUserId(data.user.uid);
          if (user.value?.roleType === RoleType.TabletUser || user.value?.roleType === RoleType.PatrolCaptain) {
            return {
              message: 'Only club admins can use this application',
              success: false,
            }
          }
          return {
            message: 'User successfully logged in',
            success: true,
          }
        } else {
          return {
            message: 'User not found in database, please contact your administrator',
            success: false,
          }
        }

      } catch (err){
        return {
          message: (err as FirestoreError).message,
          success: false,
        }
      }
  }

  async function getCurrentUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = fbAuth.onAuthStateChanged((user) => {
        userId.value = user?.uid ?? '';
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  const userId = ref<string| null>(null);

  const setUserId = (id: string) => {userId.value = id}

  const bindUserQuery = computed(() => 
    !!userId.value &&
    doc(
      db9,
      `users/${userId.value}`
    ).withConverter(converter<AdminUser>())
  )

  const loadUser = async (userId: string): Promise<AdminUser | null> => {
    const docRef = doc(
      db9,
      `users/${userId}`
    ).withConverter(converter<AdminUser>())
    try {
      const result = await getDoc(docRef);
      if (result) {
        return {...result.data(), id: result.id} as AdminUser;
      }
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
    return null;
  }

  const loadedUser = ref<AdminUser | null>(null);
  const user = useFirestore<AdminUser>(bindUserQuery, loadedUser.value)

  const signOut = async (): Promise<boolean> => {
    return signUserOut(fbAuth).then(() => {
      // Todo: Unbind user
      return true
    })
    .catch(err => {
      useInterfaceStore().NotifyError(err);
      return false
    })
  }
  

  const getUser = computed(() => {
    return user.value ?? null;
  })

  const getUserName = computed(() => {
    return `${user.value?.firstName} ${user.value?.lastName}`;
  })

  const isDeveloper = computed(() => {
    return user.value?.developer ?? false;
  })
  
  return {
    appVersion,
    user,
    userId,
    loadUser,
    loginUser,
    getCurrentUser,
    setUserId,
    signOut,
    getUser,
    getUserName,
    isDeveloper,
  }
})