import { AppNotification } from '@/models/interface/appnotification'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useInterfaceStore = defineStore("interface", () => {
  const messages = ref<AppNotification[]>([])
  const loading = ref<boolean>(false)

  const addNotification = (notification: AppNotification) => {
    messages.value.push(notification)
  }

  const removeNotification = (id: number) => {
    messages.value = messages.value.filter((m: AppNotification) => m.id !== id)
  }

  const controlLoader = (loader: boolean) => {
    loading.value = loader
  }

  const NotifyError = (message: string) => {
    const payload: AppNotification = {
      id: Date.now(),
      message,
      color: 'error',
    }
    addNotification(payload)

    setTimeout(() => {
      removeNotification(payload.id)
    }, 4000)
  }

  const NotifyWarning = (message: string) => {
    const payload: AppNotification = {
      id: Date.now(),
      message,
      color: 'info',
    }
    addNotification(payload)

    setTimeout(() => {
      removeNotification(payload.id)
    }, 4000)
  }

  const NotifySuccess = (message: string) => {
    const payload: AppNotification = {
      id: Date.now(),
      message,
      color: 'success',
    }
    addNotification(payload)

    setTimeout(() => {
      removeNotification(payload.id)
    }, 4000)
  }

  const setLoader = (setLoader: boolean) => {
    controlLoader(setLoader)
  }

  return {
    messages,
    loading,
    NotifyError,
    NotifyWarning,
    NotifySuccess,
    setLoader,
  }
});
