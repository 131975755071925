import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { QueryDocumentSnapshot, collection, getFirestore, setLogLevel } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';
export const isTraining = import.meta.env.VITE_IS_TRAINING === 'true'

//
const firebaseConfig: Record<string, any> = {
  apiKey: import.meta.env.VITE_FB_APIKEY,
  authDomain: import.meta.env.VITE_FB_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FB_DB_URL,
  projectId: import.meta.env.VITE_FB_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FB_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FB_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FB_APP_ID,
}

export const isDev = firebaseConfig.projectId === 'lnda-4672b'

const trainingFirebaseConfig: Record<string, any> = {
  apiKey: import.meta.env.VITE_FB_TRAINING_APIKEY,
  authDomain: import.meta.env.VITE_FB_TRAINING_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FB_TRAINING_DB_URL,
  projectId: import.meta.env.VITE_FB_TRAINING_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FB_TRAINING_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FB_TRAINING_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FB_TRAINING_APP_ID,
}


export const fb = firebase.initializeApp(firebaseConfig, 'main')
export const trainingFb = isTraining ? firebase.initializeApp(trainingFirebaseConfig, 'training') : null;

export const functions = fb.functions()
export const db9 = getFirestore(fb);
export const fbAuth = getAuth(fb)
export const firebaseStorage = getStorage(fb)

export const trainingFunctions = trainingFb ? trainingFb.functions() : null;
export const trainingDb = trainingFb ? getFirestore(trainingFb) : null;
export const traingFbAuth = trainingFb ? getAuth(trainingFb) : null;

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// DocRefs
export const usersRef = collection(db9, 'users');
export const clubsRef = collection(db9, 'clubs');
export const activeMembersRef = collection(db9, 'overThirteenSlsnrCRMMembersWithAwards');
export const allMembersRef = collection(db9, 'slsnrCRMMembersWithAwards');
export const incidentsRef = collection(isTraining ? trainingDb! : db9, 'incident');
export const patrolCaptainsRef = collection(isTraining ? trainingDb! : db9, 'patrolCaptainsReport');
export const headCountsRef = collection(isTraining ? trainingDb! : db9, 'headCounts');
export const patientsRef = collection(isTraining ? trainingDb! : db9, 'patients');
export const communicationsRef = collection(isTraining ? trainingDb! : db9, 'communications');
export const rosterRef = collection(isTraining ? trainingDb! : db9, 'timeSheet');
export const safeSwimRef = collection(isTraining ? trainingDb! : db9, 'safeSwim');
export const dbReference = isTraining ? trainingDb! : db9;

export interface IFirebaseError {
  code: string;
  message: string;
  stack?: string;
}

export function isFirebaseError(err: any): err is IFirebaseError {
  return err.code && err.code.startsWith('auth/');
}

export const converter = <T>() => ({
  toFirestore: (data: any) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => {
    // Convert timestamps to date here
    const data = snap.data();
      
    for (const key in data) {
      if (data[key] instanceof Timestamp) {
        data[key] = data[key].toDate();
      }
      if (typeof data[key] === 'object') {
        for (const key2 in data[key]) {
          if (data[key][key2] instanceof Timestamp) {
            data[key][key2] = data[key][key2].toDate();
          }
        }
      }
      // Drill into objects into arrays
      if (Array.isArray(data[key])) {
        for (const item of data[key]) {
          if (typeof item === 'object') {
            for (const key2 in item) {
              if (item[key2] instanceof Timestamp) {
                item[key2] = item[key2].toDate();
              }
            }
          }
        }
      }
    }
    return { id: snap.id, ...data } as T;
  },
});