import {   db9, dbReference } from "@/firebase";
import { Application, DataChangeType, LogLevel, SystemLog } from "@/models/types";
import { useAuthStore } from "@/store/auth";

import * as Sentry from "@sentry/browser";
import { addDoc, collection } from "firebase/firestore";

interface NamedParameters {
  logType: LogLevel;
  taskName: string;
  dataChanged: string;
  dataChangeType: DataChangeType;
}

const logEvent = async ({
  logType,
  taskName,
  dataChanged,
  dataChangeType}: NamedParameters): Promise<void> => {

  const userName = useAuthStore().getUserName;
  const userId = useAuthStore().user?.id ?? '';
  const userRole = useAuthStore().user?.roleType ?? null;
  
  const newSystemLog: SystemLog = {
    logType,
    userName,
    userId,
    userRole,
    timeCreated: new Date(),
    application: Application.ADMIN,
    operatingSystem: navigator.userAgent ?? '',
    taskName,
    dataChanged,
    dataChangeType,
  }
  try {
    const ref = collection(dbReference, `systemLog`)
    await addDoc(ref, newSystemLog)
   // await db.collection('systemLog').add(newSystemLog);
  } catch (error) {
    Sentry.captureException(error, {tags: {
      section: "system-logs",
    }});
  }
  return;
}

export default logEvent;