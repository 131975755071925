import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';

import { isTraining } from '@/firebase';
import { DataChangeType, LogLevel, RoleType } from '@/models/types';
import logEvent from '@/Services/logging';
import { useAuthStore } from '@/store/auth';
import { useInterfaceStore } from '@/store/interface';
import { DateTime } from 'luxon';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Auth/LoginView.vue'),
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/Auth/WelcomeView.vue'),
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('@/views/Auth/ResetPasswordView.vue'),
  },
  {
    path: '/accountdeletion',
    name: 'accountdeletion',
    component: () => import('@/views/Auth/RequestAccountDeletion.vue'),
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/Map/MapView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/clubs/ordering',
    name: 'admin.clubs.ordering',
    component: () => import('@/views/Admin/Clubs/ClubOrderingDialog.vue'),
    meta: {
      requiresAuth: true,
      
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Admin/AdminView.vue'),
    meta: {
      clubAdmin: true,
      requiresAdmin: true,
      requiresAuth: true,
      title: "Admin"
    },
    children: [
      {
        path: 'users',
        name: 'admin.users',
        component: () => import("@/views/Admin/Users/Users.vue"),
        children: [
          {
            path: ':userid',
            name: 'admin.users.id',
          } as RouteRecordRaw
        ],
        meta: {
          title: "Users",
          requiresAdmin: true,
          clubAdmin: false,
        }
      },
      {
        path: 'clubs',
        name: 'admin.clubs',
        component: () => import("@/views/Admin/Clubs/Clubs.vue"),
        children: [
          {
            path: ':clubid',
            name: 'admin.clubs.id',
          } as RouteRecordRaw
        ],
        meta: {
          title: "Clubs",
          requiresAdmin: true,
          clubAdmin: true,
        }
      },
      {
        path: 'members',
        name: 'admin.members',
        component: () => import("@/views/Admin/Members/Members.vue"),
        children: [
          {
            path: ':memberid',
            component: () => import("@/views/Admin/Members/Members.vue"),
            name: 'admin.members.id',
          }
        ],
        meta: {
          title: "Members",
          requiresAdmin: true,
          clubAdmin: true,
        }
      },
      {
        path: 'sar-teams',
        name: 'admin.sar-teams',
        component: () => import("@/views/Admin/SARTeams/SARTeams.vue"),
        children: [
          {
            path: ':sar-teamId',
            component: () => import("@/views/Admin/SARTeams/SARTeams.vue"),
            name: 'admin.sar-teams.id',
          }
        ],
        meta: {
          title: "SAR Teams",
          requiresAdmin: true,
          clubAdmin: false,
        }
      },
      {
        path: 'contact',
        name: 'admin.contact',
        component: () => import("@/views/Admin/Contact/Contact.vue"),
        children: [
          {
            path: 'message-templates',
            name: 'admin.message-templates',
            component: () => import("@/views/Admin/Contact/MessageTemplates.vue"),
            children: [
              {
                path: ':message-templateId',
                component: () => import("@/views/Admin/Contact/MessageTemplates.vue"),
                name: 'admin.message-template.id',
              }
            ],
            meta: {
              title: "Message Templates",
              requiresAdmin: true,
              clubAdmin: false,
            }
          },
          {
            path: 'contact-groups',
            name: 'admin.contact-groups',
            component: () => import("@/views/Admin/Contact/ContactGroups.vue"),
            children: [
              {
                path: ':contact-groupId',
                component: () => import("@/views/Admin/Contact/ContactGroups.vue"),
                name: 'admin.contact-group.id',
              }
            ],
            meta: {
              title: "Contact Groups",
              requiresAdmin: true,
              clubAdmin: false,
            }
          },
          {
            path: 'contacts',
            name: 'admin.contacts',
            component: () => import("@/views/Admin/Contact/Contacts.vue"),
            children: [
              {
                path: ':contactId',
                component: () => import("@/views/Admin/Contact/Contacts.vue"),
                name: 'admin.contact.id',
              }
            ],
            meta: {
              title: "Contacts",
              requiresAdmin: true,
              clubAdmin: false,
            }
          },
        ],
        meta: {
          title: "Contact Groups",
          requiresAdmin: true,
          clubAdmin: false,
        }
      },
      {
        path: 'forms',
        name: 'admin.forms',
        component: () => import("@/views/Admin/Forms/Forms.vue"),
        children: [
          {
            path: ':formId',
            component: () => import("@/views/Admin/Forms/Forms.vue"),
            name: 'admin.forms.id',
          }
        ],
        meta: {
          title: "Forms",
          requiresAdmin: true,
          clubAdmin: false,
        }
      },
      {
        path: 'survey',
        name: 'admin.survey',
        component: () => import("@/views/Admin/WeatherSurvey/WeatherSurvey.vue"),
        meta: {
          title: "survey",
          requiresAdmin: true,
          clubAdmin: false,
        }
      },
    ]
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/Reports/AllReports.vue'),
    meta: {
      requiresAuth: true,
      title: 'Reports'
    }
  },
  {
    path: '/report/search',
    name: 'report.reportType.table',
    component: () => import('@/views/Reports/ReportPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Reports'
    },
  },
  {
    path: '/report/ora',
    name: 'report.reportType.ora',
    component: () => import('@/views/Reports/Report.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':reportType/:reportId',
        name: 'report.reportType.ora.report',
        component: () => import('@/views/Reports/Pages/ORA.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: '/report/vessel',
    name: 'report.reportType.vessel',
    component: () => import('@/views/Reports/Report.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':reportType/:reportId',
        name: 'report.reportType.vessel.report',
        component: () => import('@/views/Reports/Pages/VesselIncident.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: '/report/patrol-captain',
    name: 'report.reportType.pc',
    component: () => import('@/views/Reports/Report.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':reportType/:reportId',
        name: 'report.reportType.pc.report',
        component: () => import('@/views/Reports/Pages/patrolCaptain.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  // {
  //   path: '/:reportType/:report',
  //   name: 'report.reportType.report',
  //   component: () => import('@/views/Reports/Report.vue'),
  //   meta: {
  //     requiresAuth: true,

  //   },
  // },
  // {
  //   path: '/incidents',
  //   name: 'incidents',
  //   component: () => import('@/views/Incidents/IncidentsView.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('@/views/Feedback/FeedbackPage.vue'),
    meta: {
      requiresAuth: true,
      title: "Feedback"
    }
  },
  {
    path: '/communications',
    name: 'communications',
    component: () => import('@/views/Communications/Communications.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      coastGuard: true,
      dutyOfficer: true,
      title: "Communications"
    }
  },
  {
    path: '/communications-archive/:date',
    name: 'communications-archive',
    component: () => import('@/views/Communications/CommunicationsArchive.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      coastGuard: true,
      dutyOfficer: true,
      title: "Communications Archive"
    }
  },
  {
    path: '/sar-overview/:incidentId/:sARTeamId',
    name: 'sar-overview',
    component: () => import('@/components/Communications/SearchAndRescueOverview.vue'),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      coastGuard: true,
      dutyOfficer: true,
      title: "SAR Overview"
    }
  },
  {
    path: '/contact-view',
    name: 'contact-view',
    component: () => import('@/components/Communications/ContactDialog.vue'),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      coastGuard: true,
      dutyOfficer: true,
      title: "SMS"
    }
  },
  {
    path: '/asset-management',
    name: 'asset-management',
    component: () => import('@/views/AssetManagement/AssetsTable.vue'),
    meta: {
      clubAdmin: true,
      requiresAuth: true,
      requiresAdmin: true,
      dutyOfficer: true,
      title: 'Asset Management'
    },
  },
  {
    path: '/asset-management/:asset',
    name: 'asset-management.asset',
    component: () => import('@/views/AssetManagement/Asset.vue'),
    meta: {
      clubAdmin: true,
      requiresAuth: true,
      requiresAdmin: true,
      dutyOfficer: true,
      title: 'Asset'
    },
  },
  {
    path: '/asset-management/types',
    name: 'asset-management.types',
    component: () => import('@/views/AssetManagement/AssetsTypesTable.vue'),
    meta: {
      clubAdmin: true,
      requiresAuth: true,
      requiresAdmin: true,
      dutyOfficer: true,
      title: 'Asset Types'
    },
  },
  {
    path: '/asset-management/:type',
    name: 'asset-management.type',
    component: () => import('@/views/AssetManagement/AssetType.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      dutyOfficer: true,
      title: 'Asset Type'
    },
  },
  {
    path: '/systemlog/:date',
    name: 'systemlog.date',
    component: () => import('@/views/SystemLog/SystemLog.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      dutyOfficer: true,
      title: 'System Log'
    }
  },
  {
    path: '/systemlog',
    redirect: { name: 'systemlog.date', params: { date: DateTime.fromJSDate(new Date).toFormat("yyyy-LL-dd") } },
    name: 'systemlog',
    component: () => import('@/views/SystemLog/SystemLog.vue'),
    children: [], // This is required to make the route work
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      dutyOfficer: true,
      title: 'System Log'
    }
  },
  {
    path: '/ripprediction/:date',
    name: 'ripprediction.date',
    component: () => import('@/views/RipPrediction/RipPrediction.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      dutyOfficer: true,
      weatherUser: true,
    },
  },
  {
    path: '/ripprediction',
    redirect: {
      name: 'ripprediction.date',
      params: { date: DateTime.fromJSDate(new Date).toFormat("yyyy-LL-dd") },
    },
    name: 'ripprediction',
    component: () => import('@/views/RipPrediction/RipPrediction.vue'),
    children: [], // This is required to make the route work
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      dutyOfficer: true,
      weatherUser: true,
    },
  },
  {
    path: '/rippredictionnz/:date',
    name: 'rippredictionnz.date',
    component: () => import('@/views/RipPrediction/RipPredictionNZ.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      dutyOfficer: true,
      weatherUser: true,
    },
  },
  {
    path: '/rippredictionnz',
    redirect: {
      name: 'rippredictionnz.date',
      params: { date: DateTime.fromJSDate(new Date).toFormat("yyyy-LL-dd") },
    },
    name: 'rippredictionnz',
    component: () => import('@/views/RipPrediction/RipPredictionNZ.vue'),
    children: [], // This is required to make the route work
    meta: {
      requiresAuth: true,
      dutyOfficer: true,
      requiresAdmin: false,
      weatherUser: true,
    },
  },
  {
    path: '/headcounts/:date',
    name: 'headcounts.date',
    component: () => import('@/views/Headcounts/HeadCountsOverview.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/headcounts',
    redirect: { name: 'headcounts.date', params: { date: DateTime.fromJSDate(new Date).toFormat("yyyy-LL-dd") } },
    name: 'headcounts',
    component: () => import('@/views/Headcounts/HeadCountsOverview.vue'),
    children: [], // This is required to make the route work
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/patient/:id',
    name: 'patient',
    component: () => import('@/views/Patient/PatientView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/patrolstats/:date',
    name: 'patrol',
    component: () => import('@/views/PatrolStats/PatrolStatsView.vue'),
    meta: {
      requiresAuth: true,
      coastGuard: true,
      title: 'Patrol stats'
    }
  },
  // {
  //   path: '/patrolstat/:patrolid',
  //   name: 'singlepatrol',
  //   component: () => import('@/views/PatrolStats/SinglePatrolStat.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  {
    path: '/seasons-schedule',
    name: 'schedule.select',
    component: () => import('@/views/SeasonsSchedule/ScheduleAdmin.vue'),
    meta: {
      requiresAuth: true,
      title: 'Seasons Schedule'
    }
  },
  {
    path: '/seasons-schedule/:seasonid/:clubid',
    name: 'schedule.edit',
    component: () => import('@/views/SeasonsSchedule/SchedulerTable.vue'),
    meta: {
      requiresAuth: true,
      title: 'Season Schedule'
    }
  },
  {
    path: '/scheduler/table',
    name: 'schedule-table',
    component: () => import('@/views/SeasonsSchedule/SchedulerTable.vue'),
    meta: {
      requiresAuth: true,
      title: 'Season Schedule'
    }
  },
  {
    path: '/training-overview',
    name: 'training-overview',
    component: () => import('@/views/TrainingInstance/TrainingOverview.vue'),
    meta: {
      requiresAuth: true,
      requiresTraining: true,
      title: 'Training Overview'
    }
  },
  {
    path: '/applink',
    name: 'applink',
    component: () => import('@/views/Applink/applink.vue'),
    meta: {
      requiresAuth: false,
      title: 'SLSNZ App Link'
    }
  },
  
  { path: "/:pathMatch(.*)*", name: 'pagenotfound', component: () => import('@/views/PageNotFound.vue') }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Todo: Implement nav guard
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const interfaceStore = useInterfaceStore();

  const requiresAuth = to.matched.every((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.every((record) => record.meta.requiresAdmin);
  const requiresTraining = to.matched.every((record) => record.meta.requiresTraining);
  const coastGuard = to.matched.every((record) => record.meta.coastGuard);
  const weatherUser = to.matched.every((record) => record.meta.weatherUser);
  const clubAdmin = to.matched.every((record) => record.meta.clubAdmin as boolean);
  const dutyOfficer = to.matched.every((record) => record.meta.dutyOfficer);


  let userId = await authStore.userId;
  if (!userId) {
    const user = await authStore.getCurrentUser();
    if (user) {
      userId = user.uid;
      authStore.setUserId(userId);
    }
  }
  let surfUser = authStore.user;
  if (!surfUser && userId) {
    surfUser = await authStore.loadUser(userId);
  }

  if (from.name === 'login' && surfUser !== null) {
    next();
    return;
  }

  if (requiresAuth && surfUser === null) {
    next({ name: 'login' });
  } else {
    // Check for CoastGuard role and bypass admin checks
    if (coastGuard && surfUser?.roleType === RoleType.CoastGuard) {
      next();
      return;
    }
    // Check for ClubAdmin role and bypass admin checks
    if (clubAdmin && surfUser?.roleType == RoleType.SLSClubAdmin) {
      next();
      return;
    }

     // Check for DutyOfficer role and bypass admin checks
     if (dutyOfficer && surfUser?.roleType == RoleType.DutyOfficer) {
      next();
      return;
    }


    // Check for Admin and AdminAccess roles
    if (
      (requiresAdmin) &&
      (surfUser?.roleType === RoleType.SLSAdmin)
    ) {
      next();
      return;
    } else if (
      (requiresAdmin) &&
      (surfUser?.roleType !== RoleType.SLSAdmin)
    ) {
      interfaceStore.NotifyError(
        'You do not have permission to view this page'
      );
      logEvent({
        dataChangeType: DataChangeType.UPDATE,
        dataChanged: 'Nothing',
        logType: LogLevel.WARNING,
        taskName: `User tried navigating to a protected page: ${String(
          to.name ?? ''
        )}`,
      });
      next({ name: from.name ?? 'map' });
    }

    // Check for WeatherUser role
    if (
      surfUser?.roleType === RoleType.WeatherUser &&
      (weatherUser || to.name === 'map' || to.name === 'ripprediction')
    ) {
      if (weatherUser) {
        next();
      } else if (to.name === 'map') {
        next({ name: 'ripprediction' });
      } else {
        next({ name: 'login' });
      }
      return;
    } 

    // Check for Training requirement
    if (requiresTraining && !isTraining) {
      interfaceStore.NotifyError('You must be in the training environment to view this page');
      logEvent({
        dataChangeType: DataChangeType.UPDATE,
        dataChanged: 'Nothing',
        logType: LogLevel.WARNING,
        taskName: `User tried navigating to a protected page: ${String(to.name ?? '')}`,
      });
      next({ name: from.name ?? 'map' });
      return;
    } 

    // Check for login redirection
    if (to.name === 'login' && surfUser !== null) {
      next({ name: 'map' });
      return;
    }

    // Set document title
    if (to.meta.title) {
      document.title = to.meta.title as string;
    } else {
      document.title = 'Surf Patrol';
    }

    // Default action if no conditions are meet
    next();
  }
});



export default router;
